import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { get } from 'lodash'

const Image = ({ image, childImageSharp: { fluid }, className }) => {
  const alt = get(image, 'alt') || ''

  return fluid ? (
    // <img src={get(image, 'url')} alt={alt} className={className} />
    <Img {...{ fluid, alt, className }} />
  ) : (
    <img src={get(image, 'url')} alt={alt} className={className} />
  )
}

Image.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
  }),
  childImageSharp: PropTypes.shape({
    fluid: PropTypes.object,
  }),
  className: PropTypes.string,
}

Image.defaultProps = {
  image: null,
  childImageSharp: {},
  className: '',
}

export default Image
